<template>
    <div class="py-3 pl-2">
        <h5 class="text-center">
            Resumen de denuncias recibidas período
            <strong>{{ `${startText} - ${endText}` }}</strong>
        </h5>

        <el-collapse class="w-100 border-bottom-0 mb-3" v-model="activeNames">
            <el-collapse-item name="1" class="div-filters">
                <template slot="title">
                    <h5 class="pl-3">
                        <i class="el-icon-plus mr-2" />
                        Ver fitros
                    </h5>
                </template>

                <div class="row mx-2 mb-4">
                    <div class="col-md-5 pl-0 pb-3">
                        <el-select
                            class="w-100"
                            v-model="payload.country"
                            disable
                            multiple
                            filterable
                            placeholder="Filtra por país"
                            @change="
                                (value) => {
                                    selectAll(value, 'country', 'countries');
                                }
                            "
                        >
                            <el-option label="Todos" value="all"></el-option>

                            <el-option
                                v-for="item in countries"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>

                        <el-select
                            class="mt-3 w-100"
                            v-model="payload.state"
                            disable
                            multiple
                            filterable
                            placeholder="Filtra por estado de incidente"
                            @change="
                                (value) => {
                                    selectAll(value, 'state', 'complaintState');
                                }
                            "
                        >
                            <el-option label="Todos" value="all"></el-option>

                            <el-option
                                v-for="item in complaintState"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="col-md-5 pl-0 pb-3">
                        <el-select
                            class="w-100"
                            v-model="payload.anonymous"
                            disable
                            multiple
                            filterable
                            placeholder="Filtra por tipo de denuncia"
                            @change="
                                (value) => {
                                    selectAll(
                                        value,
                                        'anonymous',
                                        'optionsAnonimus'
                                    );
                                }
                            "
                        >
                            <el-option label="Todos" value="all"></el-option>

                            <el-option
                                v-for="item in optionsAnonimus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>

                        <el-date-picker
                            class="mt-3 w-100"
                            v-model="monthRange"
                            type="monthrange"
                            align="right"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="Inicio"
                            end-placeholder="Fin"
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </div>

                    <div
                        class="col-md-2 d-flex align-items-center justify-content-end"
                    >
                        <button
                            class="btn btn-info text-white mr-2"
                            @click="filter"
                        >
                            Filtrar
                        </button>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Descargar"
                            placement="top"
                        >
                            <el-button
                                class="btn-big-icon h-100"
                                type="success"
                                plain
                                @click="downloadComplaints"
                                icon="big-icon el-icon-download"
                            />
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Limpiar filtros"
                            placement="top"
                        >
                            <el-button
                                class="btn-big-icon h-100"
                                plain
                                @click="clean"
                                icon="big-icon el-icon-brush"
                            />
                        </el-tooltip>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>

        <!-- ----------------------- -->

        <div class="row px-3 my-2">
            <div class="col">
                <div class="col mb-5">
                    <pie-chart
                        :chart-data="dataAnonymous"
                        :options="{
                            ...optionsBasic,
                            onClick: redirectToComplaint,
                            name: 'anonymous',
                            title: {
                                display: true,
                                text: 'Tipos de denuncia',
                                fontSize: 16,
                                fontColor: '#212529',
                            },
                            legend: {
                                position: 'right',
                            },
                        }"
                        :styles="myStyles"
                    >
                    </pie-chart>
                </div>

                <div class="col mb-5">
                    <bar-chart
                        :styles="myStylesCountry"
                        :options="{
                            ...options,
                            onClick: redirectToComplaint,
                            name: 'country',
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            min: 0,
                                            stepSize: 3,
                                        },
                                    },
                                ],
                            },
                            title: {
                                display: true,
                                text: 'Países de incidentes',
                                fontSize: 16,
                                fontColor: '#212529',
                            },
                        }"
                        :chart-data="dataIncidentsCountry"
                    >
                    </bar-chart>
                </div>
            </div>

            <div class="col">
                <div class="col mb-5">
                    <pie-chart
                        :chart-data="dataIncidentState"
                        :options="{
                            ...optionsBasic,
                            onClick: redirectToComplaint,
                            name: 'state',
                            title: {
                                display: true,
                                text: 'Estados de incidentes',
                                fontSize: 16,
                                fontColor: '#212529',
                            },
                            legend: {
                                position: 'right',
                            },
                        }"
                        :styles="myStyles"
                    ></pie-chart>
                </div>

                <div class="col mb-5">
                    <pie-chart
                        :chart-data="dataInvestigator"
                        :options="{
                            ...optionsBasic,
                            onClick: redirectToComplaint,
                            name: 'investigator',
                            title: {
                                display: true,
                                text: 'Denuncias por investigador',
                                fontSize: 16,
                                fontColor: '#212529',
                            },
                            legend: {
                                position: 'right',
                            },
                        }"
                        :styles="myStyles"
                    >
                    </pie-chart>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col my-2">
                <bar-chart
                    :styles="myStilyType"
                    :options="{
                        ...options,
                        onClick: redirectToComplaint,
                        name: 'type',
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        min: 0,
                                        stepSize: 3,
                                    },
                                },
                            ],
                        },
                        title: {
                            display: true,
                            text: 'Tipos de incidentes',
                            fontSize: 16,
                            fontColor: '#212529',
                        },
                    }"
                    :chart-data="dataIncidentType"
                >
                </bar-chart>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 my-1">
                <hr />

                <h5 class="text-left">
                    Cantidad de incidentes presentados por mes en el período
                    indicado
                </h5>

                <div class="justify-content-center text-center">
                    <line-chart
                        :chart-data="datacollectionMonth"
                        :options="options"
                        :styles="myStyles"
                    ></line-chart>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 my-1">
                <hr />

                <h5 class="text-left">
                    Cantidad Total de incidentes presentados en los últimos 12
                    meses
                </h5>

                <div class="justify-content-center text-center">
                    <line-chart
                        :chart-data="datacollection"
                        :options="options"
                        :styles="myStyles"
                    ></line-chart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: ["countries", "complaintState", "complaintType", "data"],
    data() {
        return {
            activeNames: [],
            startText: moment().format("MM/yyyy"),
            endText: moment().format("MM/yyyy"),
            payload: {
                start: moment().format("yyyy-MM"),
                end: moment().format("yyyy-MM"),
                country: [],
                state: [],
                anonymous: [],
            },
            filterComplaint: {
                anonymous: [],
                countries: [],
                monthRange: [
                    moment().format("_MM/yyyy"),
                    moment().format("_MM/yyyy"),
                ],
                states: [],
                types: [],
            },
            monthRange: [moment(), moment()],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "Este mes",
                        onClick(picker) {
                            picker.$emit("pick", [new Date(), new Date()]);
                        },
                    },
                    {
                        text: "Últimos 6 meses",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setMonth(start.getMonth() - 6);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "Últimos 12 meses",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setFullYear(start.getFullYear() - 1);
                            start.setMonth(start.getMonth() + 1);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "Este año",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), 0);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            datacollection: null,
            datacollectionMonth: null,
            dataAnonymous: null,
            dataIncidentState: null,
            dataIncidentType: null,
            dataIncidentsCountry: null,
            dataInvestigator: null,
            height: 15,
            width: 10,
            optionsBasic: {
                responsive: true,
                maintainAspectRatio: false,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                stepSize: 1,
                            },
                        },
                    ],
                },
            },
            optionsAnonimus: [
                {
                    value: "1",
                    label: "Anónimo",
                },
                {
                    value: "0",
                    label: "No anónimo",
                },
            ],
        };
    },
    methods: {
        clean() {
            this.payload = {
                start: moment().format("yyyy-MM"),
                end: moment().format("yyyy-MM"),
                country: [],
                state: [],
                anonymous: [],
            };
        },
        checkMobile() {
            let check = false;
            (function (a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                        a
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                        a.substr(0, 4)
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        redirectToComplaint(pointer, chart) {
            if (!this.truthty(chart[0])) {
                return;
            }
            const { controller } = chart[0]._chart.controller;
            let activePoint = controller.getElementAtEvent(pointer);
            let {
                _model: { datasetLabel, label },
                _chart: {
                    options: { name },
                },
            } = activePoint[0];
            let find;
            if (this.truthty(datasetLabel)) find = datasetLabel.split(" | ")[0];
            else find = label.split(" | ")[0];
            let filters = this.copy(this.filterComplaint);

            switch (name) {
                case "anonymous":
                    filters.anonymous = this.optionsAnonimus.find(
                        (x) => x.label == find
                    ).value;
                    break;
                case "country":
                    filters.countries = this.countries.find(
                        (x) => x.name == find
                    ).id;
                    break;
                case "state":
                    filters.states = this.complaintState.find(
                        (x) => x.name == find
                    ).id;
                    break;
                case "type":
                    let element = this.complaintType.find(
                        (x) => x.type == find
                    );
                    filters.types = this.truthty(element.id) ? element.id : -1;
                    break;
            }
            const url = `${
                window.location.origin
            }/lista-denuncias?${this.objectToUrlPath(filters)}`;
            window.open(url, "_blank");
        },
        filter() {
            const url = `${window.location.origin}/dashboard`;
            let payload = this.copy(this.payload);
            // if (!this.truthty(this.monthRange) || !this.truthty(this.monthRange)) {
            //   this.$message.error({
            //     message: "Requiere informar los periodos",
            //     showClose: true,
            //   });
            //   return;
            // }
            if (this.truthty(this.monthRange)) {
                payload.start = moment(this.monthRange[0], "yyyy-MM");
                this.startText = payload.start.format("MM/yyyy");
                payload.end = moment(this.monthRange[1], "yyyy-MM");
                this.endText = payload.end.format("MM/yyyy");
            } else {
                payload.start = "";
                payload.end = "";
                this.startText = moment().format("MM/yyyy");
                this.endText = moment().format("MM/yyyy");
            }

            if (
                this.payload.country.includes("all") ||
                !this.truthty(this.payload.country)
            ) {
                payload.country = "all";
            }
            if (
                this.payload.state.includes("all") ||
                !this.truthty(this.payload.state)
            ) {
                payload.state = "all";
            }
            if (
                this.payload.anonymous.includes("all") ||
                !this.truthty(this.payload.anonymous)
            ) {
                payload.anonymous = "all";
            }
            this.filterComplaint = {
                anonymous: this.payload.anonymous,
                countries: this.payload.country,
                monthRange: [
                    payload.start.format("_MM/yyyy"),
                    payload.end.format("_MM/yyyy"),
                ],
                states: this.payload.state,
                types: [],
            };
            axios.post(url, payload).then((response) => {
                this.datacollection = response.data.registryLastTwelveMonths;
                this.datacollectionMonth = response.data.registrySelectedMonths;
                this.dataAnonymous = response.data.anonymousData;
                this.dataIncidentState = response.data.incidentsStateData;
                this.dataIncidentType = response.data.incidentsTypeData;
                this.dataIncidentsCountry = response.data.incidentsCountryData;
                this.dataInvestigator = response.data.incidentsInvestigatorData;
            });
        },
        downloadComplaints() {
            const url = `${window.location.origin}/complaints/export`;
            let filter = this.copy(this.filterComplaint);
            filter.monthRange = this.monthRange;
            axios
                .post(url, filter, { responseType: "blob" })
                .then((response) => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.ms-excel",
                        })
                    );
                    const link = document.createElement("a");
                    const tst = moment().format("DD/MM/YYYY hh:mm:ss");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Denuncias y recomendaciones " + tst + ".xlsx"
                    );
                    document.body.appendChild(link);
                    link.click();
                    // this.successMessage = "Se ha generado correctamente el excel.";
                })
                .catch((error) => {
                    // this.errorMessage =
                    //   "Error al descargar excel, por favor intentar nuevamente o comunicarse con soporte.";
                });
        },
        selectAll(values, type, props) {
            let addAll = values.includes("all");
            if (addAll) {
                const sup = this.truthty(props) ? props : type;
                this.payload[type] =
                    type !== "anonymous"
                        ? this.$props[sup].map((x) => x.id)
                        : this.optionsAnonimus.map((x) => x.value);
            }
        },
        searchMaxLength(data) {
            let max = 0;
            if (data) {
                data.datasets.forEach((element) => {
                    if (element.data[0] > max) {
                        max = element.data[0];
                    }
                });
                if (max > 23 && max < 150) {
                    return 1;
                } else if (max > 150) {
                    return 2;
                } else {
                    return 3;
                }
            }
        },
        switchHeight(data) {
            switch (data) {
                case 1:
                    return {
                        height: `${this.height * 1.5}rem`,
                        position: "relative",
                    };
                case 2:
                    return {
                        height: `${this.height * 2}rem`,
                        position: "relative",
                    };
                case 3:
                    return {
                        height: `${this.height}rem`,
                        position: "relative",
                    };
            }
        },
    },
    computed: {
        myStyles() {
            return {
                height: `${this.height}rem`,
                position: "relative",
            };
        },
        myStylesv2() {
            return {
                height: `${this.height * 1.5}rem`,
                position: "relative",
            };
        },
        myStilyType() {
            let position = this.searchMaxLength(this.dataIncidentType);
            this.switchHeight(position);
        },
        myStylesCountry() {
            let position = this.searchMaxLength(this.dataIncidentsCountry);
            this.switchHeight(position);
        },
    },
    mounted() {
        this.datacollection = this.data.registryLastTwelveMonths;
        this.datacollectionMonth = this.data.registrySelectedMonths;
        this.dataAnonymous = this.data.anonymousData;
        this.dataIncidentState = this.data.incidentsStateData;
        this.dataIncidentType = this.data.incidentsTypeData;
        this.dataIncidentsCountry = this.data.incidentsCountryData;
        this.dataInvestigator = this.data.incidentsInvestigatorData;
        if (this.checkMobile() == false) {
            this.activeNames = ["1"];
        }
    },
};
</script>
